import Store from "@/store";
import FavoriteApi from "@/lib/api/Favorite";
import Translations from "@/lib/Translations";

const $t = Translations.Get;

const FavoriteHelper = {
    hasRecipes: false,
    hasDayMenu: false,
};

FavoriteHelper.initRecipes = async () => {
    const favoriteRecipes = await FavoriteApi.Recipe.GetAll();

    await Store.dispatch("favorites/setFavoriteRecipes", {
        favoriteRecipes,
    });
    FavoriteHelper.hasRecipes = true;
};

FavoriteHelper.initDayMenus = async () => {
    const favoriteDayMenus = await FavoriteApi.DayMenu.GetAll();

    await Store.dispatch("favorites/setFavoriteDayMenus", {
        favoriteDayMenus,
    });
    FavoriteHelper.hasDayMenu = true;
};

FavoriteHelper.onFavoriteToggle = async (favoriteObject) => {
    if (typeof favoriteObject.getClassType !== "function") {
        console.warn(
            `class of type: ${favoriteObject.constructor.name}
             is not supported for favorite toggle
             no favorite toggle will happen`
        );
        return;
    }

    const type = favoriteObject.getClassType();
    let method = "";
    switch (type) {
        case "Hit":
            method = "getObjectID";
            break;
        case "Recipe":
            method = "getId";
            break;
        case "DayMenu":
            method = "getSlug";
            break;
    }

    const id = favoriteObject[method]();
    let isFavorite;
    try {
        isFavorite = await FavoriteApi[type].Toggle(id);
    }
    catch (err) {
        Store.dispatch("toasts/sendToast", {
            type: "error",
            message: err,
        });
        return;
    }

    if (!FavoriteHelper[`update${type}State`]) {
        throw `Function update${type}State does not exist in favorite helper`;
    }

    await FavoriteHelper[`update${type}State`](isFavorite, favoriteObject);

    return isFavorite;
};

FavoriteHelper.updateHitState = async (isFavorite, hit) => {
    FavoriteHelper.updateRecipeState(isFavorite, hit);
};

FavoriteHelper.updateRecipeState = (isFavorite, recipe) => {
    const recipeTitle = recipe.getTitle();

    if (isFavorite) {
        Store.dispatch("favorites/addFavoriteRecipe", { recipe });
        Store.dispatch("favorites/setFavoriteRecipeIds");

        Store.dispatch("toasts/sendToast", {
            type: "success",
            message: $t("{recipeTitle} was added as favorite recipe", {
                recipeTitle,
            }),
        });
        return;
    }

    Store.dispatch("favorites/removeFavoriteRecipe", { recipe });
    Store.dispatch("favorites/setFavoriteRecipeIds");

    if (isFavorite) {
        return;
    }

    Store.dispatch("toasts/sendToast", {
        type: "success",
        message: $t("{recipeTitle} was removed as favorite recipe", {
            recipeTitle,
        }),
    });
};

FavoriteHelper.updateDayMenuState = (isFavorite, dayMenu) => {
    if (isFavorite) {
        Store.dispatch("favorites/addFavoriteDayMenu", { dayMenu });
        Store.dispatch("favorites/setFavoriteDayMenuIds");

        Store.dispatch("toasts/sendToast", {
            type: "success",
            message: $t("Day menu was added as favorite"),
        });
        return;
    }

    Store.dispatch("favorites/removeFavoriteDayMenu", { dayMenu });
    Store.dispatch("favorites/setFavoriteDayMenuIds");

    if (isFavorite) {
        return;
    }

    Store.dispatch("toasts/sendToast", {
        type: "success",
        message: $t("Day menu was removed as favorite"),
    });
};

export default FavoriteHelper;
