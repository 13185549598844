import DataObject from "../DataObject";
import config from "@/config";

const DAY_MENU_VARIANT__MOMENT_ORDER = {
    breakfast: "Breakfast",
    between_breakfast_lunch: "Nibbles",
    lunch: "Lunch",
    between_lunch_dinner: "Nibbles",
    dinner: "Dinner",
    snack: "Snack",
};

export default class DayMenuVariant extends DataObject {
    constructor(data) {
        super(data);
    }

    getMoments() {
        let moments = this.getData("moments");
        let resp = [];

        let order = Object.keys(DAY_MENU_VARIANT__MOMENT_ORDER);

        for (let i = 0; i < order.length; i++) {
            const moment = order[i];

            if (!moments[moment]) {
                continue;
            }

            resp.push({
                moment: DAY_MENU_VARIANT__MOMENT_ORDER[moment],
                html: moments[moment],
                kilocalories: moments[moment + "_kilocalories"],
            });
        }

        return resp;
    }

    getPdfUrl() {
        let pdf = this.getData("pdf");

        if (!pdf) {
            return null;
        }

        return `${config.media_url}/pdf-download/${pdf.id}`;
    }
}
