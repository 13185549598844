import DataObject from "./DataObject";
import Comment from "./Comment";
import config from "@/config";
import placeholder from "@/assets/images/placeholder.jpg";

const TIME_PROPERTIES = {
    H: "hour",
    M: "minutes",
    S: "seconds",
};

// ! PRO-416
export default class Recipe extends DataObject {
    constructor(data) {
        super(data);
    }

    getMedia(size = null) {
        const media = this.getData("media/imageConversions");

        if(!media) {
            return placeholder;
        }

        if (!size) {
            return media;
        }

        return new URL(media[size], config.media_url).toString();
    }

    getPreperationTimes() {
        return this.getData("preparation_times").map((time) => {
            return {
                ...time,
                preparation_time_indication:
                    TIME_PROPERTIES[time.preparation_time_property],
            };
        });
    }

    getMainPreperationTime() {
        let preperation_times = this.getPreperationTimes();

        return preperation_times[0];
    }

    getDetailedPreperationTimes() {
        let preperation_times = this.getPreperationTimes();

        return preperation_times.filter((prep_time, index) => index > 0);
    }

    // ! PRO-417
    getNutrients(filterEmpty = false) {
        let nutrients = this.getData("nutrients");

        nutrients = nutrients
            .map((nutrient) => {
                if (
                    (filterEmpty && parseFloat(nutrient.pivot.amount) < 0.01) ||
                    nutrient.code === "Kcal"
                ) {
                    return null;
                }
                //round added to value, ES-3171
                return {
                    code: nutrient.code,
                    name: nutrient.value,
                    unit: nutrient.unit,
                    value: Math.round(nutrient.pivot.amount),
                };
            })
            .filter((i) => i);

        return nutrients;
    }

    // ! PRO-421
    getSteps() {
        return this.getData("steps").map((i) => i.stap);
    }

    // ! PRO-422
    getTips() {
        return (this.getData("tips")  || []).map((i) => i.tip).filter((tip) => tip);
    }

    hasTips() {
        return (this.getTips() || []).length > 0;
    }

    // ! PRO-420
    getDayMenus() {
        return this.getData("LinkedDayMenus") || [];
    }

    hasDayMenus() {
        return this.getDayMenus().length > 0;
    }

    getComments() {
        return this.getData("comments").map((comment) => new Comment(comment));
    }

    getMealTypesString() {
        const groupedTags = this.getGroupedTags();

        return (groupedTags["mealType"] || [])
            .map((mealtype) => mealtype.name)
            .join(", ");
    }

    getKcal() {
        return this.getData("computed_kcal");
    }

    getPdfUrl() {
        let pdf = this.getData("pdf");

        if (!pdf) {
            return null;
        }

        return `${config.media_url}/pdf-download/${pdf.id}`;
    }

    getClassType() {
        return "Recipe";
    }
}
