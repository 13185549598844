import Recipe from "../Recipe";
import DataObject from "../DataObject";
import DayMenuVariant from "./DayMenuVariant";
import config from "@/config";

export default class DayMenu extends DataObject {
    constructor(data) {
        super(data);
    }

    getMedia(size = null) {
        let media = this.getData("image_urls");

        if (!media) {
            return "";
        }

        if (!size) {
            return media;
        }

        if (!media[size] || !config.media_url) {
            return media;
        }

        return new URL(media[size], config.media_url).toString();
    }

    getDayMenuVariants() {
        return this.getData("day_menu_variants").map(
            (item) => new DayMenuVariant(item)
        );
    }

    getDayMenuVariantByCalories(calories) {
        if (!calories) {
            return null;
        }

        return this.getDayMenuVariants().find(
            (i) => i.getCalories() == calories
        );
    }

    getDayMenuVariantKeys() {
        return this.getDayMenuVariants().map((i) => i.getCalories());
    }

    getRecipes() {
        return this.getData("recipes").map((i) => new Recipe(i));
    }

    getClassType() {
        return "DayMenu";
    }
}
