import HTTPClient from "@/lib/HTTPClient";
import Comment from "@/models/Comment";

const Comments = {};

/**
 * Place comment on recipe
 *
 * @param   {string}  slug  Recipe slug
 * @param   {string}  text  Comment body
 *
 * @return  {Object}        response object
 */
Comments.ReplyToRecipe = async function(slug, text) {
    let resp = await HTTPClient.Post(`/recipes/${slug}/comments`, {
        data: {
            text,
        },
    });

    if (resp.status != 201 || !resp.data.comment) {
        return false;
    }

    return new Comment(resp.data.comment);
};
/**
 *
 * Place comment on other comment
 *
 * @param   {string}  slug  Recipe slug
 * @param   {string}  text  Comment body
 *
 * @return  {Object}        response object
 */
Comments.ReplyToComment = async function(commentId, text) {
    let resp = await HTTPClient.Post(`/comments/${commentId}`, {
        data: {
            text,
        },
    });

    // In contrary to replying to comments, here we get a 200 status code
    if (resp.status != 200 || !resp.data.comment) {
        return false;
    }

    return resp.data.comment;
};

Comments.GetList = async (slug, approved, page = 0) => {
    let { status, data } = await HTTPClient.Get(
        `/v3/recipes/${slug}/comments/${approved}?page=${page}`
    );

    if (status != 200) {
        return false;
    }

    data.data = data.data.map((comment) => new Comment(comment));

    return data;
};

export default Comments;
