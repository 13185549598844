import HTTPClient from "@/lib/HTTPClient";
import Recipe from "@/models/Recipe";
import DayMenu from "@/models/DayMenu/DayMenu";

const Favorite = {};

Favorite.Recipe = {
    Toggle: async (recipeId) => {
        const { data, status } = await HTTPClient.Post(
            "/v3/recipes/favorites/toggle",
            {
                data: {
                    recipe_id: recipeId,
                },
            }
        );

        if (status != 200) {
            let errorMsg = data.message || "";

            if (data.errors && Object.keys(data.errors).length > 0) {
                errorMsg = Object.values(data.errors)[0];
            }

            throw new Error(errorMsg);
        }

        return data.favorite;
    },
    GetIdList: async () => {
        const { data, status } = await HTTPClient.Get("/v3/recipes/favorites");

        if (status != 200) {
            let errorMsg = data.message || "";

            if (data.errors && Object.keys(data.errors).length > 0) {
                errorMsg = Object.values(data.errors)[0];
            }

            throw new Error(errorMsg);
        }

        return data.data.map((recipe) => recipe.id);
    },
    GetAll: async () => {
        const { data, status } = await HTTPClient.Get("/v3/recipes/favorites");

        if (status != 200) {
            let errorMsg = data.message || "";

            if (data.errors && Object.keys(data.errors).length > 0) {
                errorMsg = Object.values(data.errors)[0];
            }

            throw new Error(errorMsg);
        }

        return data.data.map((i) => new Recipe(i));
    },
};

Favorite.Hit = {
    Toggle:  Favorite.Recipe.Toggle,
    GetIdList: Favorite.Recipe.GetIdList,
    GetAll: Favorite.Recipe.GetAll,
};

Favorite.DayMenu = {
    Toggle: async (dayMenuSlug) => {
        // ! PRO-527
        const { data, status } = await HTTPClient.Post(
            "/v3/daymenus/favorites/toggle",
            {
                data: {
                    slug: dayMenuSlug,
                },
            }
        );

        if (status != 200) {
            let errorMsg = data.message || "";

            if (data.errors && Object.keys(data.errors).length > 0) {
                errorMsg = Object.values(data.errors)[0];
            }

            throw new Error(errorMsg);
        }

        // ! PRO-527
        return data.favorite;
    },
    GetAll: async () => {
        const { data, status } = await HTTPClient.Get("/v3/daymenus/favorites");

        if (status != 200) {
            let errorMsg = data.message || "";

            if (data.errors && Object.keys(data.errors).length > 0) {
                errorMsg = Object.values(data.errors)[0];
            }

            throw new Error(errorMsg);
        }

        return data.data.map((i) => new DayMenu(i));
    },
};

export default Favorite;
