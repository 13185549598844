import DataObject from "./DataObject";
import CommentsApi from "@/lib/api/Comments";
import { DateTime } from "@/lib/Misc";

import config from "@/config";

export default class Comment extends DataObject {
    constructor(data) {
        super(data);
    }

    getAvatar() {
        if (this.isAdmin()) {
            return "/img/placeholder-sticky.jpg";
        }

        return null;
    }

    getName() {
        if (this.isAdmin()) {
            return config.comments.admin_name;
        }

        return `${this.getData("first_name")} ${this.getData("last_name")}`;
    }

    getInitials() {
        let name = this.getName();
        let initials = name.match(/\b\w/g) || [];
        return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    }

    isAdmin() {
        return this.getData("customer_id") === 0;
    }

    getDate() {
        return DateTime.Date(this.getData("created_at"));
    }

    getFormattedDate() {
        return DateTime.ConvertDateToHumanReadable(this.getDate(), true, true, true, false);
    }

    getComments() {
        if (!this.getData("comments")) {
            return [];
        }

        return this.getData("comments")
            .map(comment => new Comment(comment));
    }

    async addReply(text) {
        let comment = await CommentsApi.ReplyToComment(this.getId(), text);

        if (!comment) {
            return false;
        }

        let comments = this.getData("comments");
        comments.push(comment);
        this.setData("comments", comments);
        return comment;
    }
}
